<header
  class="header__wrapper flex justify-center items-center border-b border-gray-100 md:border-b bg-white fixed w-full z-999"
  [ngClass]="{ 'is-sticky': isSticky }">
  <div
    class="flex md:px-14 w-full bg-white header__wrapper--inner xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md max-w-screen-sm h-full relative z-20">
    <ng-container *ngIf="!isScreenSmall">
      <div class="header__logotipo">
        <a [routerLink]="['/', activeLang]">
          <img src="assets/images/logo/logo-1.png" alt="{{ 'Motorbest' | transloco }}" class="max-h-18 w-auto"
            loading="lazy" />
        </a>
      </div>

      <div class="header__content flex flex-1 justify-end items-center mb-3">
        <div class="header__menu flex items-center">
          <!-- Horizontal navigation -->
          <targx-horizontal-site-navigation [name]="'mainNavigation'"
            [navigation]="navigation?.site"></targx-horizontal-site-navigation>
          <button [routerLink]="['/', activeLang,'private','my-cars']" mat-flat-button [color]="'primary'"
            (click)="toggleNavigation('mainNavigation')">
            {{'Announce' | transloco}}
          </button>
        </div>
        <div class="header__actions pl-3 border-l ml-3 flex items-center min-h-6">

          <user></user>
          <search [appearance]="'fullscreen'" class="mr-6" (search)="onSearchEvent($event)"></search>
          <languages></languages>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isScreenSmall">
      <div class="flex justify-between w-full h-full pr-3">
        <div class="header__logotipo flex items-center col-span-3 relative grow">
          <a [routerLink]="['/']" class="absolute -bottom-3">
            <img src="assets/images/logo/logo-1.png" alt="{{ 'Motorbest' | transloco }}" class="max-h-16 w-auto"
              loading="lazy" />
          </a>
        </div>

        <div class="items-center h-full flex">
          <user [onlyIcon]="true"></user>
          <languages></languages>
          <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'" class="text-black-motorbest-800"></mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Navigation -->
  <ng-container *ngIf="isScreenSmall">
    <div class="navigation__mobile" @slideOutTop @slideInTop *ngIf="openNavigation">
      <div class="navigation__mobile--wrapper border-b border-gray-300">
        <div class="navigation__mobile--item" [ngClass]="{
            'nav-mobile-basic': item.type === 'basic',
            'nav-mobile-group': item.type === 'group',
            'nav-mobile-open': menuOpen === item.id,
          }" *ngFor="let item of navigation.site">
          <ng-container *ngIf="item.type === 'basic'">
            <a [routerLink]="[item.link]" [target]="item.target"
              class="navigation__mobile--item--title navigation__mobile--item--link">
              {{ item.title | transloco }}
            </a>
          </ng-container>

          <ng-container *ngIf="item.type === 'group'">
            <button class="navigation__mobile--item--title navigation__mobile--item--group" (click)="toggleMenu(item)">
              {{ item.title | transloco }}
              <mat-icon svgIcon="mat_outline:arrow_drop_down" class="text-gray-400"
                *ngIf="menuOpen !== item.id"></mat-icon>
              <mat-icon svgIcon="mat_outline:arrow_drop_up" class="text-gray-400"
                *ngIf="menuOpen === item.id"></mat-icon>
            </button>

            <div class="navigation__mobile--subitem navigation__mobile--subitem--wrapper" @slideOutTop @slideInTop>
              <ng-container *ngFor="let childItem of item.children">
                <ng-container *ngIf="childItem.type === 'basic'">
                  <a [routerLink]="[childItem.link]" [target]="childItem.target"
                    class="navigation__mobile--subitem--title navigation__mobile--subitem--link">
                    {{ childItem.title | transloco }}
                  </a>
                </ng-container>

                <ng-container *ngIf="childItem.type === 'group'">
                  <button class="navigation__mobile--subitem--title navigation__mobile--subitem--group"
                    (click)="toggleMenu(item)">
                    {{ childItem.title | transloco }}
                    <mat-icon svgIcon="mat_outline:arrow_drop_down" class="text-gray-400"></mat-icon>
                    <mat-icon svgIcon="mat_outline:arrow_drop_up" class="text-gray-400"></mat-icon>
                  </button>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="navigation__mobile--actions flex flex-col gap-3 py-6 ">
        <search [appearance]="'fullscreen'" class="mr-6" (search)="onSearchEvent($event)" class="mb-6"></search>
        <user></user>
      </div>
    </div>
    <div class="overlay fixed top-0 left-0 h-full w-full bg-black opacity-30 z-0" *ngIf="openNavigation"
      (click)="toggleNavigation()"></div>
  </ng-container>
</header>
