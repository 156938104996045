import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  NgForm,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseAlertType } from '@fuse/components/alert';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseUtilsService } from '@fuse/services/utils';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { FormService } from './form.service';

@Component({
  selector: 'widget-form',
  templateUrl: './form-widget.component.html',
  styleUrls: ['./form-widget.component.scss'],
})
export class FormWidgetComponent extends WidgetBaseComponent implements OnInit {
  @ViewChild('dynamicNgForm') dynamicNgForm: NgForm;

  form: UntypedFormGroup = this.formBuilder.group({});

  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: '',
  };

  showAlert = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _utilService: FuseUtilsService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private ac: ChangeDetectorRef,
    private _fuseConfirmationService: FuseConfirmationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.handleFields(
      this.widget.formulario?.data?.attributes?.Fields.filter((field) =>
        [
          'forms.text',
          'forms.dropdown',
          'forms.checkbx',
          'forms.radio',
          'forms.textarea',
        ].includes(field.__component),
      ),
    );

    this.ac.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  handleFields(fields: any) {
    const formGroup = {};
    fields.forEach((field) => {
      this.form.addControl(
        field.Name,
        new FormControl(
          '',
          field.Required ? Validators.compose([Validators.required]) : null,
        ),
      );
    });

    return formGroup;
  }

  getResponviceClasses(widgets) {
    if (!widgets?.Columns && !widgets.Colunas) {
      return 'col-span-12 w-full';
    }
    const colClasses = this._utilService.generateResponsiveClass(
      widgets?.Columns ?? widgets.Colunas,
    );
    this.ac.markForCheck();
    return `${widgets.__component.replace('.', '_')} ${colClasses}`;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    // Disable the form
    this.form.disable();

    // Hide the alert
    this.showAlert = false;

    const data = {
      formName: this.widget.formulario.data.attributes.Subject,
      formData: {
        id: this.widget.id,
        url: window.location.href,
        ...this.form.value,
      },
    };

    this.formService.send(data).subscribe(
      (response) => {
        // Open the confirmation dialog
        const confirmation = this._fuseConfirmationService.open({
          title: 'Success',
          message: 'Your form has been submitted successfully!',
          icon: {
            show: true,
            name: 'heroicons_outline:check-circle',
            color: 'success',
          },
          actions: {
            confirm: {
              label: 'Ok',
            },
            cancel: {
              show: false,
            },
          },
        });
        confirmation.afterClosed().subscribe((result) => {
          // Check if the user confirmed
          if (result === 'confirmed') {
            // Do something when the user confirms
            window.location.reload();
          }
          // Re-enable the form
        });
      },
      (response) => {
        // Re-enable the form
        this.form.enable();

        // Reset the form
        this.dynamicNgForm.resetForm();

        // Set the alert
        this.alert = {
          type: 'error',
          message:
            'Por favor, verifique se todos os campos estão bem preenchidos',
        };

        // Show the alert
        this.showAlert = true;
      },
    );
  }
}
